/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';

import Layout from '../../components/layout';
import './policy.css';

const PolicyPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Layout>
        <div className="container">
          <h1 className="text-secondary">{t('terms.title')}</h1>
          <span className="text-secondary">{t('terms.updated')}</span>

          <div className="policy-line" />

          <p className="text-secondary">{t('terms.term')}</p>

          <p className="text-secondary">{t('terms.term1')}</p>

          <p className="text-secondary">{t('terms.term2')}</p>

          <p className="text-secondary">{t('terms.term3')}</p>

          <p className="text-secondary">{t('terms.term4')}</p>

          <p className="text-secondary">{t('terms.term5')}</p>

          <p className="text-secondary">{t('terms.term6')}</p>

          <p className="text-secondary">{t('terms.termFooter')}</p>
        </div>
      </Layout>
    </>
  );
};

export default PolicyPage;
